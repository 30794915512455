.penalty {
  &__list {
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    margin-bottom: 10px;
  }

  &__row {
    display: grid;
    grid-template-columns: 200px 1fr 1fr;
    border-bottom: 1px solid #dadada;

    &:last-child {
      border: none
    }
  }

  &__worker-info {
    font-weight: bold;
  }

  &__c {
    padding: 4px 5px;
    border-right: 1px solid #dadada;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }
  }

  &__create {
    padding: 5px 10px;
  }
}