.suspended-table {
    td {
        padding: 3px 10px;
    }

    //zebra
    tr:nth-child(even) {
        background-color: #ececec;
    }

    &__sum {
        text-align: right;
    }

    tr{
        &._hide{
            display: none;
        }
    }
}