.store-worker-day {
  &__form {
    min-width: 500px;
  }

  ._dateTime {
    select {
      width: 30%;
    }

    .store-worker-day__value-wrapper div {
      display: flex;
      justify-content: space-between;
    }
  }

  .choice-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4px;

    label {
      margin-bottom: 0;
      padding-left: 4px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__form-line {
    display: grid;
    grid-template-areas: "label value"
                             "_ errors";
    grid-template-columns: 150px 1fr;
  }

  &__label-wrapper {
    grid-area: label;
  }

  &__value-wrapper {
    grid-area: value;
  }

  &__error-wrapper {
    grid-area: errors;
  }
}