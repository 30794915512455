:root {
  --default-input-height: 30px;
  --errors-color: var(--alert-color, #b50000)
}

$inputs: 'input[type="text"], input[type="search"], input[type="password"]';

form {
  #{$inputs}, textarea, select {

  }

  label {
    color: black;
    margin-bottom: 2px;
    display: block;
    font-weight: 400;
    line-height: 110%;
    font-stretch: 110%;
    font-variation-settings: var(--font-settings);
  }

  ul.errors {
    margin-top: 10px;
    list-style: none;
    color: var(--errors-color);
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$inputs}, textarea, select {
    margin: 0;
    font-size: 14px;
    width: 100%;
    display: block;
    font-family: var(--font);
    padding: 3px 7px;
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }

  textarea {
    padding: 5px 10px;
  }
}