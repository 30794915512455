.month-edit {
  font-size: 14px;
  margin-top: 30px;

  &__nav {
    max-width: 500px;
  }

  &__nav-list {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }

  &__nav-item {

  }

  &__nav-link {
    font-weight: bold;
    text-decoration: underline;

    &._current {
      text-decoration: none;
      color: #a1a1a1;
    }
  }

  &__table {
    border-top: 1px solid #dadada;
    color: #000;
  }

  &__row {
    display: flex;
    border-bottom: 1px solid #dadada;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f1f1f1;
    }

    &_head {
      font-weight: bold;
    }
  }

  &__c-worker {
    padding: 4px 0;
    flex: 0 0 200px;
    border-right: 1px solid #dadada;
  }

  &__c-day {
    padding: 4px 0;
    flex: 0 0 34px;
    text-align: center;
    border-right: 1px solid #dadada;

    &_value {
      font-size: 12px;
      position: relative;
    }

    &._holiday {
      background-color: #eae9e9;
    }

    &._next {
      .month-edit {
        &__value {
          opacity: 0.2;
          filter: grayscale(1);
        }
      }
    }
  }

  &__c-day-link {
    &._has-comment {
      color: red;
    }
  }

  &__c-action {
    padding: 4px 0;
    flex: 0 0 250px;
    border-right: 1px solid #dadada;
  }

  &__c-role {
    padding: 4px 0;
    flex: 0 0 120px;
    text-align: center;
    border-right: 1px solid #dadada;
  }

  &__action-prices {
    margin-top: 20px;
  }

  &__value {
    &_fired {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  &__value_link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__add {
    color: #bd7979;
  }


  &__roles-bar {
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }

  &__roles-bar-item {
    width: 4px;
    height: 4px;
    border-radius: 10px;

    &._storekeeper {
      background-color: red;
    }

    &._driver {
      background-color: green;
    }

    &._loader {
      background-color: blue;
    }

    &._packer {
      background-color: #f300f3;
    }

    &._manager {
      background-color: #009692;
    }
  }

  &__is-temporary {
    color: #0000ab;
  }

  &__is-rest {
    color: #9800cc;
  }

  &__is-penalty {
    color: #9b00ff;
    font-weight: bold;
    font-size: 14px;
  }

  &__is-break {
    color: #fa6900;
  }

  &__is-trip {
    color: #d3a20a;
  }
}