.footer {
  display: none;
  background: var(--background-color);

  &__bottom-left-wrap {
    display: flex;
  }

  &__kssk-logo {
    svg {
      path {
        fill: black;
      }
    }
  }

  .footer {
    &__field {
      .footer {
        &__input {
          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px var(--background-color) inset;
          }
        }
      }
    }
  }

  &__form-policy-wrap {
    color: var(--primary-color-opacity-40);
  }

  &__form-policy-link {
    border-bottom: 1px solid var(--primary-color-opacity-40);
  }

  &__input {
    background: var(--background-color);
  }

  &__wrap {
    grid-row-gap: 0;
  }

  &__rclass {
    color: var(--primary-color-opacity-40);
  }

  &__rclass-link {
    color: black;
    margin-left: 4px;
  }

  &__bottom-wrap {
    display: flex;
    justify-content: space-between;
  }
}