.month-calc {
  &__workers {
    display: flex;
  }

  &__worker {
    margin-bottom: 20px;
    flex: 0 0 300px;
    width: 300px;
  }

  &__worker-name {
    font-size: 120%;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__split {
    font-size: 12px;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }

  &__worker-totals {
    margin-top: 10px;
  }

  &__worker-totals-title {
    font-weight: bold;
  }

  &__worker-details-actions {
    padding-left: 10px;
  }

  &__worker-details {
    margin-top: 10px;

    &._opened {
      .month-calc__worker-details-wrap {
        display: block;
      }
    }
  }

  &__worker-details-wrap {
    display: none;
  }

  &__worker-details-title {
    font-weight: bold;
    margin-bottom: 7px;
    display: block;
  }

  &__worker-details-day {
    margin-bottom: 5px;
  }

  &__r {
    display: flex;

    &:first-child {
      font-weight: bold;
      background-color: #fff;
      top: 0;
      position: sticky;
      z-index: 100;

      .month-calc__c {
        font-size: 12px;
      }
    }

    &_totals {
      font-weight: bold;
    }

    &:hover {
      .month-calc__c {
        background-color: rgba(0,0,0,0.1);
      }
    }
  }

  &__sub-r {
    display: flex;
    border-right: 2px solid #101010;

    &._hidden {
      .month-calc__c:not([data-group-totals]) {
        display: none;
      }
    }
  }

  &__c {
    width: 85px;
    flex: 0 0 85px;
    font-size: 14px;
    padding: 3px 5px;
    border-right: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    text-align: right;
    transition: background-color 0.3s;
    position: relative;

    &-border-right {
      border-right: 2px solid #101010;
    }


    &_worker {
      width: 180px;
      flex: 0 0 180px;
      text-align: left;
    }

    &_day {
      font-weight: bold;
    }

    &:hover {
      .month-calc__c-hidden {
        display: block;
      }
    }
  }

  &__c-hidden {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    pointer-events: none;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 100;
    padding: 3px;
  }

  &__payslips {
    margin-bottom: 30px;
  }

  [data-group-toggle] {
    cursor: pointer;
  }
}