.suspended-filter{
  display: grid;
  grid-template-columns: 300px 1fr 1fr 1fr;
  width: fit-content;
  gap: 20px;
  margin-bottom: 30px;

  input{
    height: 30px;
  }

  input[type='checkbox']{
    height: auto;
  }
}