h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  text-transform: uppercase;
  font-stretch: 151%;
  font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 60;
  font-style: normal;
}

h1, .h1, h2, .h2, h3, .h3 {
  font-weight: 600;
  line-height: 120%;
}

h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 500;
  line-height: 110%;
}

h1, .h1 {
  --h-size: 30px;
}

h2, .h2 {
  --h-size: 20px;
}

h3, .h3 {
  --h-size: 15px;
}

h4, .h4 {
  --h-size: 15px;
}

h5, .h5 {
  --h-size: 15px;
}

h6, .h6 {
  --h-size: 15px;
}